import "scss/index.scss";

import {plugin_registry} from 'nk-plugin-registry';
// import your plugins here.
import 'image/js/image';
import 'title/js/name';
import 'contentbox/js/content-box';
import 'socialmedia/js/socialmedia';
import 'section/js/section';
import 'image_gallery/js/image_gallery';
import 'events/js/events';
import './navbar';
import 'backgroundimage/js/backgroundimage';
import 'video/js/video';

plugin_registry.init();

