import "../scss/video.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";
import "viewerjs/dist/viewer.css";

@plugin_registry.register("video")
class SingleVideo extends PluginBase {
    constructor($node) {
        super($node);
    }

    disconnect($node) {
        super.disconnect($node);

    }


    loaded($node) {
        super.loaded($node);

        $node.addEventListener('click', (event) => {
            // Find the hidden span with the URL inside this wrapper
            const urlElement = $node.querySelector('.video-url');
            const instanceURL = urlElement.textContent.trim();

            const consent = $node.querySelector('.video-consent')

            const iframe = document.createElement('iframe');

            consent.style.display = "none"

            iframe.width = '100%';
            iframe.src = instanceURL;
            iframe.title = 'YouTube video player';
            iframe.frameBorder = '0';
            iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';
            iframe.referrerPolicy = 'strict-origin-when-cross-origin';
            iframe.allowFullscreen = true;

            // Append the iframe to the wrapper
            $node.appendChild(iframe);
        });


    }
}