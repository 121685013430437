import "../scss/backgroundimage.scss";

import {in_view, plugin_registry, PluginBase, resize_observing} from "nk-plugin-registry"

@plugin_registry.register("BackgroundImage")
@resize_observing
@in_view
class Backgroundimage extends PluginBase {
    constructor($node) {
        super($node);
        this._$node = $node;
    }

    connect($node) {
        super.connect($node);
        console.log('connect');
    }

    loaded($node) {
        super.loaded($node);
        console.log('Loaded Background');
    }

    disconnect($node) {
        super.disconnect($node);
        console.log('disconnect');
    }

    resize_observed() {
        console.log('resize_observed');
    }

    enters() {

    }
}
