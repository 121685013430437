export default function dispatchCustomEventOnNode($node, eventType, payload) {
  const customEvent = new CustomEvent(
    eventType,
    {
      bubbles: true,
      detail: payload,
    },
  );

  // dispatch event for project
  $node.dispatchEvent(customEvent);
}
