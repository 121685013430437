/**
 * Thrown if the Life Cycle Manager is initialized multiple times.
 * @param message
 * @class
 */
class RegistryInitializedMultipleTimesException {
  constructor(message) {
    this.message = message;
    this.name = 'RegistryInitializedMultipleTimesException';
  }

  toString = () => `${this.name} ${this.message}`
}

// eslint-disable-next-line import/prefer-default-export
export { RegistryInitializedMultipleTimesException };
