import { RegistryInitializedMultipleTimesException } from './exceptions';
import dispatchCustomEventOnNode from './tools/dispatchCustomEventOnNode';

const MUTATION_TYPE = {
  CHILD_LIST: 'childList',
};

const PLUGIN_REMOVED_EVENT_NAME = 'PLUGIN_REMOVED_EVENT_NAME';
const PLUGIN_ADDED_EVENT_NAME = 'PLUGIN_ADDED_EVENT_NAME';

/**
 * A Simple Life Cycle Manager for frontend plugins.
 * @author Stephan S. Hepper (s.hepper@netzkolchose.de)
 * @class
 */
class PluginRegistry {
  /**
   * create a plugin life cycle registry and manager
   * this should be used as a singleton only.
   */
  constructor() {
    this._plugin_mappings = new Map();
    this._known_elements = new Map();
    this._is_initialized = false;
    this._call_loaded_registry = new Map();

    this._mutation_observer_node = document;

    const mutation_observer_default_config = {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: false,
      attributeFilter: [
        'data-plugin',
      ],
    };
    this._mutation_observer_config = {
      ...mutation_observer_default_config,
    };
  }

  /**
   * Register a Class as plugin for life cycle management. As soon as an element equipped with the corresponding
   * data-plugin attribute is added to the DOM (but latest on DOM ready) an instance of the decorated Class is
   * created by the plugin life cycle registry.
   * @classdecorator
   * @param {string} plugin_name - The name of the plugin you use as the data-plugin attribute
   *
   * @example
   * import { plugin_registry, PluginBase } from 'framework/plugin';
   *
   * // note: remove the trailing underscore (_).
   * //       It's a work around for jsdoc to deal with ECMA decorator syntax.
   * _@plugin_registry.register('MyPlugin')
   * class MyPlugin extends PluginBase {
   *   constructor($node) {
   *     // The constructor is called with the parameter `element` when
   *     // the element is added to the DOM and latest on DOM ready.
   *     // Note: You must call super($) in your implementation.
   *     super($note);
   *   }
   *
   * disconnect($node) {
   *     // disconnect is called right before `element` is removed from the DOM.
   *     // Note: You must call super in your implementation.
   *     super.disconnect($node);
   *   }
   * }
   */
  register = (plugin_name) => (Class) => {
    this._plugin_mappings.set(plugin_name, Class);
    return Class;
  };

  /**
   * This method attaches mutation observers and the DOM ready event handler. It must be called after all plugins
   * for life cycle management have been registered.
   * @throws RegistryInitializedMultipleTimesException
   */
  init = () => {
    if (!this._is_initialized) {
      this._mutation_observer = new MutationObserver(this._mutation_observed);
      this._attach_mutation_observers();
      this._attach_dom_ready();
      this._is_initialized = true;
    } else {
      throw new RegistryInitializedMultipleTimesException('Plugin registry initialization was invoked multiple times. Probably you tried to call .init() more than once.');
    }
  };

  /**
   * Returns the instance of a life cycle managed plugin for a given node if it has been initialized already.
   * @param $node - a DOM node
   * @returns {object} - the instance or `undefined`
   */
  plugin_instance_from_node = ($node) => this._known_elements.get($node);

  plugin_instances = () => this._known_elements;

  /**
   * A generator to filter a NodeList for element nodes. The returned generator also yields
   * child elements of the nodes supplied that carry the `data-plugin` attribute.
   * @generator
   * @param {NodeList} node_list
   * @returns {Generator<*|Node|Node|Node|Node, void, ?>}
   * @private
   */
  * _filter_nodes(node_list) {
    for (const $node of node_list) {
      if ($node.nodeType === Node.ELEMENT_NODE) {
        const child_nodes = $node.querySelectorAll('[data-plugin]');
        if ($node.dataset.plugin) {
          yield $node;
        }

        if (child_nodes) {
          for (const $child_node of child_nodes) {
            yield $child_node;
          }
        }
      }
    }
  }

  /**
   * A generator to filter a NodeList for element nodes that have not yet been initialized by
   * life cycle management.
   * @generator
   * @param {NodeList} node_list
   * @returns {Generator<*|Node|Node|Node|Node, void, ?>}
   * @private
   */
  * _filter_initialized_nodes(node_list) {
    for (const $node of node_list) {
      if (!this._known_elements.has($node)) {
        yield $node;
      }
    }
  }

  /**
   * attach the dom ready listener
   * @private
   */
  _attach_dom_ready = () => {
    if (document.readyState === 'loading') {
      document.addEventListener(
        'DOMContentLoaded',
        this._inititalize_components,
        { passive: true, once: true },
      );

      // register event handler for deferred on load life cycle handling
      window.addEventListener(
        'load',
        this._call_loaded,
        {
          passive: true,
          once: true,
        },
      );
    } else if (document.readyState === 'interactive') {
      // `DOMContentLoaded` has already fired
      this._inititalize_components();

      // register event handler for deferred on load life cycle handling
      window.addEventListener(
        'load',
        this._call_loaded,
        {
          passive: true,
          once: true,
        },
      );
    } else {
      // page loading is complete so call plugin initialization straight through
      this._inititalize_components();
    }
  };

  _call_loaded = () => {
    for (const [$node, plugin_instance] of this._call_loaded_registry) {
      try {
        plugin_instance.loaded($node);
      } catch (error) {
        console.error(error);
      }
    }

    // remove the map to free references
    this._call_loaded_registry = null;
  }

  /**
   * The callback function for the dom ready event.
   * This will initialize all plugins that have not been initialized yet.
   * @private
   */
  _inititalize_components = () => {
    const components = document.querySelectorAll('[data-plugin]');
    this._handle_lifecycle_create(this._filter_initialized_nodes(components));
  };

  /**
   * attach the mutation observer to the document
   * @private
   */
  _attach_mutation_observers = () => {
    this._mutation_observer.observe(
      this._mutation_observer_node,
      this._mutation_observer_config,
    );
  };

  /**
   * Instantiates all registered plugins for a given list of nodes.
   * If any node has been initialized before, it will be ignored.
   * @param {NodeList} nodes - an iterable with dom nodes
   * @private
   */
  _handle_lifecycle_create = (nodes) => {
    const instances_for_initialization = [];

    for (const $node of nodes) {
      const plugin_name = $node.dataset.plugin;
      const Class = this._plugin_mappings.get(plugin_name);
      if (this._known_elements.has($node)) {
        break;
      }

      if (Class) {
        try {
          const plugin_instance = new Class($node);
          // propagate the plugin_name property of the freshly created plugin instance.
          plugin_instance.plugin_name = plugin_name;

          // add the plugin to known instances
          this._known_elements.set($node, plugin_instance);

          instances_for_initialization.push({
            plugin_instance,
            $node,
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        console.warn(`Plugin of type "${plugin_name}" is not registered.`);
      }
    }

    for (const instance_definition of instances_for_initialization) {
      try {
        const { plugin_instance, $node } = instance_definition;

        // call the the plugin instance's `connect()` method
        plugin_instance.connect($node);
        dispatchCustomEventOnNode(
          $node,
          PLUGIN_ADDED_EVENT_NAME,
          { instance: plugin_instance },
        );

        if (document.readyState === 'complete') {
          // if the document is complete then let's call loaded straight since the component has been added
          plugin_instance.loaded($node);
        } else {
          // add the component to the loaded map for defered `.loaded` call
          this._call_loaded_registry.set($node, plugin_instance);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * Calls `disconnect(element)` on all registered plugins for a given list of nodes.
   * @param {NodeList} nodes - an iterable with dom nodes
   * @private
   */
  _handle_lifecycle_remove = (nodes) => {
    for (const $node of nodes) {
      const instance = this._known_elements.get($node);
      if (instance) {
        try {
          dispatchCustomEventOnNode(
            $node,
            PLUGIN_REMOVED_EVENT_NAME,
            { instance },
          );
          instance.disconnect($node);
        } catch (error) {
          console.error(error);
        }

        this._known_elements.delete($node);
      }

      // in case the plugin is removed from the dom BEFORE it's loaded functions have been called we have to remove it
      // also from the loaded_registry
      if (this._call_loaded_registry && this._call_loaded_registry.has($node)) {
        this._call_loaded_registry.delete($node);
      }
    }
  };

  /**
   * Callback for the mutation observer.
   * @param mutations_list - the list of mutations
   * @private
   */
  _mutation_observed = (mutations_list) => {
    for (const mutation of mutations_list) {
      if (mutation.type === MUTATION_TYPE.CHILD_LIST) {
        if (mutation.addedNodes.length) {
          this._handle_lifecycle_create(this._filter_nodes(mutation.addedNodes));
        }

        if (mutation.removedNodes.length) {
          this._handle_lifecycle_remove(this._filter_nodes(mutation.removedNodes));
        }
      }
    }
  };
}

const plugin_registry = new PluginRegistry();

export { PluginRegistry, plugin_registry, PLUGIN_REMOVED_EVENT_NAME, PLUGIN_ADDED_EVENT_NAME };
