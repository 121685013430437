import "../scss/image_gallery.scss";
import {WEBP_SUPPORTED} from "helpers/webp"
import {plugin_registry, PluginBase} from "nk-plugin-registry";

import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";

@plugin_registry.register("ImageGallery")
class ImageGallery extends PluginBase {
    constructor($node) {
        super($node);
        this._overlayVisible = false;
    }

    disconnect($node) {
        super.disconnect($node);
        // Destroy all Viewer instances when disconnecting
        this._viewers.forEach(viewer => viewer.destroy());
        this._viewers = [];
    }

    toggleOverlay = ($trigger) => {
        const overlay = $trigger.querySelector('.image-overlay');
        if (!this._overlayVisible) {
            overlay.classList.add('visible');
        } else {
            overlay.classList.remove('visible');
        }
        this._overlayVisible = !this._overlayVisible;
    }

    show_gallery = (event, index) => {
        // Show the viewer for the clicked trigger
        this._viewers[index].show();
    }

    _add_gallery_trigger = ($node) => {
        this._gallery_triggers = $node.querySelectorAll('[data-js-select="gallery-trigger"]');
        this._viewers = []; // Array to store Viewer instances

        // Create a Viewer instance for each trigger element
        this._gallery_triggers.forEach(($trigger, index) => {
            const albumTitle = $trigger.getAttribute('data-album')
            const images = document.querySelector(`.album-wrapper[data-album="${albumTitle}"]`);
            const filterImages = (image) => {
                return image.dataset.album === albumTitle;
            };

            // Create a new Viewer instance for the current trigger
            const viewer = new Viewer(images, {
                className: 'gallery-viewer',
                toolbar: {
                    zoomIn: false,
                    zoomOut: false,
                    oneToOne: false,
                    reset: false,
                    prev: true,
                    play: {
                        show: false,
                        size: 'large',
                    },
                    next: true,
                    rotateLeft: false,
                    rotateRight: false,
                    flipHorizontal: false,
                    flipVertical: false,
                },
                url: (image) => {
                    return WEBP_SUPPORTED ? image.dataset['originalUrlWebp'] : image.dataset['originalUrl']
                },
            });

            // Store the Viewer instance in the array
            this._viewers.push(viewer);

            // Add click event listener to toggle overlay or open gallery
            $trigger.addEventListener('click', (event) => {
                if (!this._overlayVisible) {
                    this.toggleOverlay($trigger);
                }
            });

            const overlay = $trigger.querySelector('.image-overlay');
            overlay.addEventListener('click', (event) => {
                if (this._overlayVisible) {
                    this.show_gallery(event, index);
                    this.toggleOverlay($trigger);
                }
            });
        });
    }

    _add_random_side_overlay_event() {
        const wrappers = document.getElementsByClassName('image-wrapper');
        if (wrappers.length > 0) {
            // Loop through elements
            for (let i = 0; i < wrappers.length; i++) {
                const wrapper = wrappers[i];
                const overlay = wrapper.querySelector('.image-overlay');

                wrapper.addEventListener('mouseenter', () => {
                    this.toggleOverlay(wrapper);
                    const direction = Math.floor(Math.random() * 4);

                    switch (direction) {

                        case 0:
                            overlay.classList.add('left-to-right');
                            break;
                        case 1:
                            overlay.classList.add('right-to-left');
                            break;
                        case 2:
                            overlay.classList.add('bottom-up');
                            break;
                        case 3:
                            overlay.classList.add('up-to-right');
                            break;
                        default:
                            break;
                    }
                });
                wrapper.addEventListener('mouseleave', () => {
                    this.toggleOverlay(wrapper);
                    overlay.classList.remove('left-to-right');
                    overlay.classList.remove('right-to-left');
                    overlay.classList.remove('bottom-up');
                    overlay.classList.remove('up-to-right');
                });
            }
        } else {
            console.log("No elements found for Image Overlay");
        }
    }

    loaded($node) {
        super.loaded($node);

        this._add_gallery_trigger($node);
        this._add_random_side_overlay_event();
    }

}
