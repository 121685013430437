import debounce from 'underscore/modules/debounce';

/**
 * The `resize_observing` decorator extends a module by visibility checks. You can use it to detect if your module:
 * - enters or leaves the viewport,
 * - if it is at a certain position withing the viewport or
 * - if it is fully visible within the viewport.
 *
 * @decorator @resize_observing
 * @author Stephan S. Hepper (s.hepper@netzkolchose.de)
 * @param BaseClass
 * @return Class (Module with Resize Observing Live Cycle Functionality)
 * @example
 * import { module_registry, resize_observing, ModuleBase } from 'framework/module';
 *
 * //Note: _@ should be replaces with @ since jsdocs dosn't support generator syntax in it's examples.
 * _@module_registry.register("MyInviewModule")
 * _@resize_observing
 * class MyInviewModule extends BaseModule {
 *   resize_debounce_time  = 250; // corresponds to underscore's `wait` default `250` see http://underscorejs.org/#debounce
 *   resize_immediate = false; // corresponds to underscore's `immediate` default `false` see http://underscorejs.org/#debounce
 *
 *   resize_observed(...args) {
 *     // this function will be called, as soon as the viewport is resized.
 *   }
 * }
 *
 * //Note: `this._inview_xyz` is used as prefix to identify protected members of this decorator class for inheritance.
 */
const resize_observing = (BaseClass) => class extends BaseClass {
  is_resize_observing = true;

  constructor($node) {
    super($node);
    this._resize_observer_installed = false;
  }

  _get_debounced_resize_observed = () => debounce(
    (...args) => this.resize_observed(...args),
    this.resize_debounce_time || 250,
    this.resize_immediate || false,
  );

  connect($node) {
    super.connect($node);
    this._debounced_resize_observed = this._get_debounced_resize_observed();
    window.addEventListener(
      'resize',
      this._debounced_resize_observed,
      { passive: true },
    );
    this._resize_observer_installed = true;
  }

  resize_observer_remove_event_listeners() {
    if (this._resize_observer_installed) {
      window.removeEventListener('resize', this._resize_observed_debounced);
      this._resize_observer_installed = false;
    }
  }

  disconnect($node) {
    super.disconnect($node);
    this.resize_observer_remove_event_listeners();
  }

};

export default resize_observing;
