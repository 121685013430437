import "../scss/events.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";


@plugin_registry.register("Events")
class Events extends PluginBase {
    constructor($node) {
        console.log("Event Constructor")
        super($node);

    }

    connect($node) {
        super.connect($node);
        console.log('Connected Event');
    }

    loaded($node) {
        super.loaded($node);

        this._event_triggers = $node.querySelectorAll('[data-js-select="event-trigger"]');
        this._viewOverlay = document.querySelector(`.letmeviewthis`);

        this._event_triggers.forEach(($trigger) => {
                const eventID = $trigger.getAttribute('data-event')
                const descriptionBox = $node.querySelector(`.events-description-wrapper[data-event="${eventID}"]`);

                $trigger.addEventListener("click", (event) => {
                    event.stopPropagation();
                    descriptionBox.style.display = 'flex';
                    this._viewOverlay.style.display = 'block';
                });

                const closeButton = descriptionBox.querySelector('.close-button');
                closeButton.addEventListener("click", (event) => {
                        descriptionBox.style.display = 'none';
                        this._viewOverlay.style.display = 'none';
                    }
                )
                this._viewOverlay.addEventListener("click", (event) => {
                        descriptionBox.style.display = 'none';
                        this._viewOverlay.style.display = 'none';
                    }
                )
            }
        )

    }


    disconnect($node) {
        super.disconnect($node);
        console.log('disconnect');
    }

    resize_observed() {
        console.log('resize_observed');
    }

    enters() {

    }
}
