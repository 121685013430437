import {in_view, plugin_registry, PluginBase, resize_observing} from "./nk-plugin-registry";


@plugin_registry.register("Navbar")
@resize_observing
@in_view
class Navbar extends PluginBase {
    constructor($node) {
        super($node);
        this._$node = $node;
        this._dropdownContent = this._$node.querySelector("#dropdown-content-wrapper");
    }

    connect($node) {
        super.connect($node);
    }

    resize_observed() {
        console.log('resize_observed');
    }

    enters() {

    }

    loaded($node) {
        super.loaded($node);
        this._addEventListeners();
    }

    disconnect($node) {
        super.disconnect($node);
    }


    _addEventListeners() {
        const dropdownContent = this._dropdownContent;
        const dropdown = this._$node;

        function showDropdown() {
            dropdownContent.style.display = "flex";

        }

        function hideDropdown() {
            dropdownContent.style.display = "none";
        }

        const items = [
            this._$node.querySelector(".first"),
            this._$node.querySelector(".second"),
            this._$node.querySelector(".last")
        ]

        dropdown.addEventListener("click", (event) => {
            event.stopPropagation();
            if (dropdownContent.style.display === "flex") {
                items.map(x => x.classList.toggle("change"))
                hideDropdown();
            } else {
                items.map(x => x.classList.toggle("change"))
                showDropdown();
            }
        });

    }
}