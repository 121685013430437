import "../scss/image.scss";

import {plugin_registry, PluginBase, resize_observing, in_view} from "nk-plugin-registry"

@plugin_registry.register("Image")
@resize_observing
@in_view
class Image extends PluginBase {
  constructor($node) {
    super($node);
    console.log('Image plugin construct', $node);
    this._$node = $node;
  }

  connect($node) {
    super.connect($node);
    console.log('connect');
  }

  loaded($node) {
    super.loaded($node);
    console.log('loaded');
  }

  disconnect($node) {
    super.disconnect($node);
    console.log('disconnect');
  }

  resize_observed() {
    console.log('resize_observed');
  }

  enters() {
    console.log('enters', this._$node);
  }
}
