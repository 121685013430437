import throttle from "underscore/modules/throttle";

const INVIEW_STATE = {
  UNDEFINED: -1,
  ENTERS: 0,
  LEAVES: 1,
  VISIBLE: 2,
};
const INVIEW_CHECK_VISIBILITY_EVENT_NAME = 'inview.check_visibility';
const INVIEW_SCROLL_CONTEXT_SELECTOR = '[data-scroll-container]';


/**
 * This function allows you to manually trigger all in_view decorated Modules to recheck their visibility.
 * This is esp. useful when elements slide to the viewport from a previously invisible state.
 */
function in_view_check_visible() {
  const trigger_inview_check_visibility_event = new CustomEvent(INVIEW_CHECK_VISIBILITY_EVENT_NAME);
  document.dispatchEvent(trigger_inview_check_visibility_event);
}

/**
 * The in_view decorator extends a module by visibility checks. You can use it to detect if your module:
 * - enters or leaves the viewport,
 * - if it is at a certain position withing the viewport or
 * - if it is fully visible within the viewport.
 *
 * Be aware that the default scroll context is bound to `window`.
 * If the scrollable context differs from that, it is possible to use another DOM element as scroll context.
 * That element has to be selectable via `INVIEW_SCROLL_CONTEXT_SELECTOR`.
 *
 * @decorator @in_view
 * @author Stephan S. Hepper (s.hepper@netzkolchose.de)
 * @param BaseClass
 * @return Class (Module with InView Functionality)
 * @example
 * import { module_registry, in_view, ModuleBase } from 'framework/module';
 *
 * //Note: _@ should be replaces with @ since jsdocs dosn't support generator syntax in it's examples.
 * _@module_registry.register("MyInviewModule")
 * _@inview
 * class MyInviewModule extends BaseModule {
 *   enters(enters_count, is_down_scroll) {
 *     // this function will be called, as soon as the module's $node enters the viewport.
 *   }
 *
 *   leaves(leaves_count, is_down_scroll) {
 *     // This function will be called, as soon as the module's $node leaves the viewport.
 *   }
 *
 *   visible(visible_count, is_down_scroll) {
 *     // This function will be called, as soon as the module's $node is fully visible in the viewport.
 *   }
 *
 *   at_position(at_position_count, is_down_scroll) {
 *     // This function will be called, as soon as the module's $node is at a certain height within the viewport.
 *     // By default at_position is watching for the middle of the viewport. You can change that behaviour by defining
 *     // a property `at_position_offset` (integer range 0 to 1) e.g. in your constructor or for a more dynamic approach
 *     // a getter for that property. It is also possible to assign a value in px use a string instead e.g. "123px".
 *   }
 * }
 *
 * //Note: `this._inview_xyz` is used as prefix to identify protected members of this decorator class for inheritance.
 * //Note: use `this.inview_remove_event_listeners` to remove in_view's event listeners in case life cycle methodes
 * //      shall not be called anymore.
 */
const in_view = (BaseClass) => class extends BaseClass {
  is_in_view = true;

  constructor($node) {
    super($node);
    this._$inview_element = $node;
    this._visible_count = 0;
    this._enters_count = 0;
    this._leaves_count = 0;
    this._at_position_count = 0;
    this._at_position_blocked = false;
    this._last_scroll_top = -1;
    this.last_state = INVIEW_STATE.UNDEFINED;

    // configuration
    if (typeof this.at_position_offset === 'undefined') {
      this.at_position_offset = 0.5;
    }

    // check for additional scroll context that is not 'window'
    const $scroll_context = $node.closest(INVIEW_SCROLL_CONTEXT_SELECTOR);
    this._$event_context = $scroll_context || window;
    this._is_alternative_event_context = !!$scroll_context;

    this._$event_context.addEventListener('scroll', this._inview_check_visible_throttled);
    this._$event_context.addEventListener('resize', this._inview_check_visible_throttled);

    document.addEventListener(INVIEW_CHECK_VISIBILITY_EVENT_NAME, this._inview_check_visible);
  }

  loaded($node) {
    super.loaded($node);
    this._inview_check_visible();
  }

  disconnect($node) {
    super.disconnect($node);
    this.inview_remove_event_listeners();
  }

  /**
   * Removes all event listeners. Use this if your Module Instance doesn't need further in_view observations.
   */
  inview_remove_event_listeners = () => {
    this._$event_context.removeEventListener('scroll', this._inview_check_visible_throttled);
    this._$event_context.removeEventListener('resize', this._inview_check_visible_throttled);

    document.removeEventListener(INVIEW_CHECK_VISIBILITY_EVENT_NAME, this._inview_check_visible);
  };

  /**
   * Is called as soon as the Module's $node enters the viewport.
   * @param enters_count Integer
   * @param is_down_scroll Boolean
   */
  // enters(enters_count, is_down_scroll) {
  //   super.enters(enters_count, is_down_scroll);
  // }

  /**
   * Is called as soon as the Module's $node is fully visible within the viewport.
   * @param visible_count Integer
   * @param is_down_scroll Boolean
   */
  // visible(visible_count, is_down_scroll) {
  //   super.visible(visible_count, is_down_scroll);
  // }

  /**
   * Is called as soon as the Module's $node leaves the viewport.
   * @param leaves_count Integer
   * @param is_down_scroll Boolean
   */
  // leaves(leaves_count, is_down_scroll) {
  //   if (Object.getPrototypeOf.hasOwnProperty('leaves')) {
  //     super.leaves(leaves_count, is_down_scroll);
  //   }
  // }

  /**
   * Is called as soon as the Module's $node is at a certain height within the viewport.
   * The height can be adjusted using the `at_position_offset` property of the BaseModule.
   * You can also use a more dynamic approach by setting up a getter for the `at_position_offset` property.
   * @param at_position_count Integer
   * @param is_down_scroll Boolean
   *
   * @example
   * // in your constructor
   * this.at_position_offset = 0.25;
   * // This will trigger the `at_position` function, if the element's middle line is at a quarter height of the viewport.
   *
   * this.at_position_offset = "180px";
   * // This will trigger the `at_position` function, if the element's middle line is at 180px from the top of the viewport.
   *
   */
  // at_position(at_position_count, is_down_scroll) {
  //   try {
  //     super.at_position(at_position_count, is_down_scroll);
  //   } catch(Error) {
  //     console.info(Error);
  //   }
  // }

  /**
   * Test at_position to exist and call it only then
   * @param at_position_count
   * @param is_down_scroll
   * @private
   */
  _inview_save_call_at_position(at_position_count, is_down_scroll) {
    if (typeof this.at_position === 'function') {
      this.at_position(at_position_count, is_down_scroll);
    }
  }

  /**
   * Test enters to exist and call it only then
   * @param enters_count
   * @param is_down_scroll
   * @private
   */
  _inview_save_call_enters(enters_count, is_down_scroll) {
    if (typeof this.enters === 'function') {
      this.enters(enters_count, is_down_scroll);
    }
  }

  /**
   * Test leaves to exist and call it only then
   * @param leaves_count
   * @param is_down_scroll
   * @private
   */
  _inview_save_call_leaves(leaves_count, is_down_scroll) {
    if (typeof this.leaves === 'function') {
      this.leaves(leaves_count, is_down_scroll);
    }
  }

  /**
   * Test visible to exist and call it only then
   * @param visible_count
   * @param is_down_scroll
   * @private
   */
  _inview_save_call_visible(visible_count, is_down_scroll) {
    if (typeof this.visible === 'function') {
      this.visible(visible_count, is_down_scroll);
    }
  }


  /**
   * The primary handler for visibility checks. This is called whenever
   * - the user scrolls or resizes the viewport.
   * - by the init() method to trigger visibility checks
   * - by in_view_check_visible() via the INVIEW_CHECK_VISIBILITY_EVENT_NAME
   * @private
   */
  _inview_check_visible = () => {
    // check if size of element is defined (larger 0) otherwise it doesn't make sense to evaluate
    // visibility further
    if (this._inview_elem_height <= 0) {
      return;
    }

    // visibility checks:
    if (
      // check enters on scroll down
      this.last_state !== INVIEW_STATE.ENTERS && this.last_state !== INVIEW_STATE.VISIBLE
      && this._inview_down_scroll
      && this._inview_elem_top_offset < this._inview_window_height
      && this._inview_elem_bottom_offset > 0
    ) {
      this._enters_count += 1;
      this.last_state = INVIEW_STATE.ENTERS;
      this._at_position_blocked = false;
      this._inview_save_call_enters(this._enters_count, this._inview_down_scroll);
    } else if (
      // check if enters on scroll up
      this.last_state !== INVIEW_STATE.ENTERS && this.last_state !== INVIEW_STATE.VISIBLE
      && !this._inview_down_scroll
      && this._inview_elem_top_offset < this._inview_window_height
      && this._inview_elem_bottom_offset > 0
    ) {
      this._enters_count += 1;
      this.last_state = INVIEW_STATE.ENTERS;
      this._at_position_blocked = false;
      this._inview_save_call_enters(this._enters_count, this._inview_down_scroll);
    } else if (
      // check if fully visible inside of viewport
      this.last_state !== INVIEW_STATE.VISIBLE
      && this._inview_elem_top_offset <= this._inview_window_height
      && this._inview_elem_top_offset >= 0
      && this._inview_elem_bottom_offset <= this._inview_window_height
    ) {
      this._visible_count += 1;
      this.last_state = INVIEW_STATE.VISIBLE;
      this._at_position_blocked = false;
      this._inview_save_call_visible(this._visible_count, this._inview_down_scroll);
    } else if (
      // check if visible and higher than viewport
      this.last_state !== INVIEW_STATE.VISIBLE
      && this._inview_elem_top_offset <= 0
      && this._inview_elem_bottom_offset >= this._inview_window_height
    ) {
      this._visible_count += 1;
      this.last_state = INVIEW_STATE.VISIBLE;
      this._at_position_blocked = false;
      this._inview_save_call_visible(this._visible_count, this._inview_down_scroll);
    } else if (
      // check leaves on scroll down
      this.last_state !== INVIEW_STATE.LEAVES
      && this._inview_elem_bottom_offset <= 0
    ) {
      this._leaves_count += 1;
      this.last_state = INVIEW_STATE.LEAVES;
      this._at_position_blocked = false;
      this._inview_save_call_leaves(this._leaves_count, this._inview_down_scroll);
    } else if (
      // check leaves on scroll up
      this.last_state !== INVIEW_STATE.LEAVES
      && !this._inview_down_scroll
      && this._inview_elem_top_offset > this._inview_window_height
    ) {
      this._leaves_count += 1;
      this.last_state = INVIEW_STATE.LEAVES;
      this._at_position_blocked = false;
      this._inview_save_call_leaves(this._leaves_count, this._inview_down_scroll);
    }

    if (!this._at_position_blocked) {
      // check if at_position
      let target_line_position;
      const window_height = this._inview_window_height;
      const factor = this.at_position_offset;

      if (typeof factor === 'string') {
        target_line_position = parseInt(factor, 10);
      } else {
        target_line_position = window_height * factor;
      }

      // test if we're on the line
      if (
        this._inview_elem_top_offset < target_line_position
        && this._inview_elem_bottom_offset > target_line_position
      ) {
        this._at_position_count += 1;
        this._at_position_blocked = true;
        this._inview_save_call_at_position(this._at_position_count, this._inview_down_scroll);
      }
    }

    this._last_scroll_top = this._inview_window_scroll_offset;
  };

  /**
   * throttled version of _inview_check_visible.
   * used by the event handlers to achieve better performance.
   * @private
   */
  _inview_check_visible_throttled = throttle(this._inview_check_visible, 25, { leading: false });

  /**
   * Test if the last scroll move was a down scroll
   * @return {boolean}
   * @private
   */
  get _inview_down_scroll() {
    return this._last_scroll_top <= this._inview_window_scroll_offset;
  }

  /**
   * Test if the last scroll move was a up scroll
   * @return {boolean}
   * @private
   */
  get _inview_up_scroll() {
    return !this._inview_down_scroll;
  }

  /**
   * returns the window's scroll offset
   * @return {number}
   * @private
   */
  get _inview_window_scroll_offset() {
    const $context_element = this._is_alternative_event_context ? this._$event_context : document.documentElement;

    return (window.pageYOffset || $context_element.scrollTop) - ($context_element.clientTop || 0);
  }

  /**
   * returns the window's height
   * @return {number}
   * @private
   */
  get _inview_window_height() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }

  /**
   * returns the monitored element's height
   * @return {{enumerable: boolean}|number}
   * @private
   */
  get _inview_elem_height() {
    return this._$inview_element.offsetHeight;
  }

  /**
   * returns the offset of the monitored element from the bottom of the viewport.
   * @return {*}
   * @private
   */
  get _inview_elem_bottom_offset() {
    return this._$inview_element.getBoundingClientRect().bottom;
  }

  /**
   * returns the monitored element's top offset relative to the viewport.
   * @return {number}
   * @private
   */
  get _inview_elem_top_offset() {
    return this._$inview_element.getBoundingClientRect().top;
  }
};

export { in_view, in_view_check_visible, INVIEW_STATE };
