/**
 * Base Class for plugins with life cycle management
 * @author Stephan S. Hepper (s.hepper@netzkolchose.de)
 */
class PluginBase extends Object {
  /**
   * Called if a node with a corresponding `data-plugin` attribute is attached to the DOM or latest at DOM ready.
   * Note: You must call super in this method!
   * @param $node - The DOM node the Module is initialized for.
   *
   */
  // eslint-disable-next-line no-useless-constructor
  constructor($node) {
    super($node);
  }

  /**
   * Is called right after the constructor, as soon as the Module's Instance object is fully created and operational.
   * You can use this as an entry point.
   * Note: You must call super.connect($node) in this function!
   * @param $node - The DOM node the Module is initialized for.
   */
  connect($node) {
    try {
      super.connect($node);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  /**
   * Is called right after the document has compleated loading including assets like css and images.
   * You can use this as an entry point if you require css layout beeing already applied e.g. getting the height of an
   * element and such.
   * Note: You must call super.loaded($node) in this function!
   * @param $node - The DOM node the Module is initialized for.
   */
  loaded($node) {
    try {
      super.loaded($node);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  /**
   * Called if a node with a corresponding `data-plugin` attribute is detached from the DOM.
   * Note: You must call super in this method!
   * @param $node - The DOM node that was disconnected.
   */
  disconnect($node) {
    try {
      super.disconnect($node);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  /**
   * Contains the plugin name that a plugin was registered with.
   * It is propagated by the plugin registry right after your constructor was called, right before
   * the connect() method is executed.
   * @type String
   * @readonly
   */
  plugin_name = null;
}

export default PluginBase;
